import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import slugify from "utils/slugify"
import useScrollSpy from "utils/scrollSpy"

const TableOfContent = ({ headings, tableOfContents }) => {
  // console.log(headings)
  // console.log(tableOfContents)

  const filteredHeadings = headings.filter((heading) => heading.depth < 2)

  const activeId = useScrollSpy(
    filteredHeadings.map(({ value }) => slugify(value)),
    { rootMargin: "0% 0% -55% 0%" }
  )

  return (
    <ul
      className={`transition-all text-rightt
      ${!activeId && "opacity-0"} 
       -ml-4 pl-2 py-2 border-l-4 border-yellow-400 border-dashed`}
    >
      {filteredHeadings.map((heading) => {
        const headingId = slugify(heading.value)
        const isActiveId = headingId === activeId

        return (
          <li className="my-2">
            <Link
              to={"#" + headingId}
              className={`${
                isActiveId ? "text-zinc-900 font-bold" : "text-zinc-600"
              } hover:text-black no-underline whitespace-nowrap`}
            >
              <span className={`transition-all ${!isActiveId && "opacity-0"}`}>
                →{" "}
              </span>
              {heading.value}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default TableOfContent
