const CollaboratorWebsites = {
  // NYUSH CS People
  "Dingsu Wang": "https://github.com/Derek-Wds",
  "Yijie Zou": "https://www.billyzou.com/",
  "Chongling Zhu": "https://github.com/ozcl",

  // NYUSH IMA People
  "Ann Chen": "https://annhchen.com/",
  "Leon Eckert": "https://leoneckert.com/",
  "(as NDOI)": "https://ndoi.land/",
  "Jingtian Zong": "https://jingtianz.com/",
  "Jingyi Wang": "https://www.jingyiwang.design/",
  "Jianghao Hu": "https://samjhhu.com",
  "Richard Lewei Huang": "http://lewei.me/",
  "Cindy Yifan Hu": "http://cindy-hu.com/",
  "Phyllis Fei": "https://phyllisfei.net/",
  "Wenhe Li": "https://www.wenhe.li/",
  "Michael Naimark": "http://www.naimark.net/",
  "Cameron Ballard": "https://cameronballard.space/",
  "Barak Chamo": "https://barakchamo.com/",
  "Ivy Huang": "https://www.ivydhuang.com/",
  "David Santiano": "https://www.davidsantiano.com",

  // CMU / MHCI People
  "Diva Agarwal": "https://www.divaa.info/",
  "Salonee Gupta": "https://www.saloneegupta.me/",
  "Vera Wei": "https://www.verawei.xyz/",
  "Animesh Singh": "https://animeshyns.com/",
  "Miley Hu": "https://mileyhu.com",
  "Ziwen Zeng": "https://www.ziwenzeng98.com/",
  "Lorry Tang": "https://www.lorrytang.com/",
  "Roshan Selladurai": "https://roshofosho.com",
  "Yael Canaan": "https://www.yaelc.com",
  "Jongyeon Chae": "https://www.johnchae.com/",
  "Neha Deshmukh": "https://www.nehadeshmukh.com/",
  "Mahit Munakala": "https://www.sreemahit.com/",

  // Github People
  "Jelte Lagendijk": "https://github.com/j3lte",
}

export default CollaboratorWebsites
