const NavLinks = [
  {
    name: "All Works",
    url: "/",
    subLinks: [
      { name: "Design", url: "/?category=design" },
      { name: "Art", url: "/?category=art" },
      { name: "Other", url: "/?category=other" },
    ],
  },

  {
    name: "About",
    url: "/about",
    subLinks: [
      // {
      //   name: "Resume",
      //   url: "https://yufengzhao.com/Yufeng_Zhao_Resume_2022.pdf",
      // },
    ],
  },
  // {
  //   name: "Misc.",
  //   subLinks: [{ name: "Photos", url: "/photos" }],
  // },
  {
    name: "Contact",
    subLinks: [
      { name: "LinkedIn", url: "https://www.linkedin.com/in/yufeng-zhao/" },
      { name: "Instagram", url: "https://www.instagram.com/hallucitalgia/" },
      { name: "Github", url: "https://github.com/yz3440" },
      { name: "Email", url: "mailto:yufeng.zhao.p@outlook.com" },
    ],
  },
]

export default NavLinks
