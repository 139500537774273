import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import { navigate } from "gatsby"
import Link from "gatsby-plugin-transition-link"

const Layout = ({
  location,
  children,
  category,
  pageType,
  tableOfContent,
  headings,
  setShowMore,
}) => {
  // Enables dark mode if the user's OS has an active dark theme
  // const darkModeEnabled = useDarkMode()

  // console.log("Page Type:", pageType)

  return (
    <div className="flex flex-col md:block md:max-w-7xl m-auto ">
      {/* <HomeSketch></HomeSketch> */}

      {/* Decorations */}
      <div
        className="transition-all fixed bottom-0 left-0  bg-black 
        w-10 h-10
        md:w-6 md:h-6
        md:hover:-rotate-90 md:hover:scale-150 
        z-10 cursor-pointer md:cursor-default
      text-white md:text-black"
        onClick={() => {
          navigate("#")
        }}
      >
        <div className="flex md:hidden font-bold justify-center items-center">
          <div className="mt-2">↑</div>
        </div>
      </div>
      <div className="transition-all fixed top-0 right-0 w-6 h-6 bg-black hover:-rotate-90 hover:scale-[10] hover:bg-blue-500"></div>
      <div className="z-10 blur-lgg transition-all rounded-full fixed right-0 bottom-0 w-36 h-36 lg:w-64 lg:h-64 bg-yellow-300 translate-x-1/2 translate-y-1/2 hover:scale-150 hover:bg-blue-500"></div>
      <div className="blur-smm transition-all -z-10 rounded-full fixed left-0 top-0 w-24 h-24 lg:w-96 lg:h-96 bg-yellow-300 -translate-x-1/2 -translate-y-1/2 hover:scale-150 hover:bg-blue-500"></div>

      <div
        className={`transition-all mr-auto w-full md:h-full md:fixed border-dashed border-black border-r-0 md:border-r-22
      ${pageType === "project-detail" ? "lg:w-64 md:w-48" : "lg:w-80 md:w-64"}
      md: p-6 md:py-6`}
      >
        <Header
          location={location}
          category={category}
          pageType={pageType}
          tableOfContent={tableOfContent}
          headings={headings}
          setShowMore={setShowMore}
        />

        {/* Decorations */}
        {/* <div className="absolute right-0 bottom-0 -z-20 w-6 h-6 bg-black  hidden md:block"></div> */}
        <div className=" transition-all absolute right-0 bottom-0  -z-20 w-6 h-12 bg-blue-500 translate-x-full translate-y-1/2 hidden md:block hover:h-[256rem]"></div>
        <div className=" transition-all absolute right-0 bottom-0  -z-20 w-6 h-12 bg-black translate-x- translate-y-1/2 hidden md:block hover:w-[128rem]"></div>
      </div>

      <main
        className={`transition-all md:ml-auto w-full md:w-auto col-start-4
        ${
          pageType === "project-detail"
            ? "lg:ml-64 md:ml-48"
            : "lg:ml-80 md:ml-64"
        }
        px-4 md:px-6 mb-16`}
        id="main-content"
      >
        <div>{children}</div>
        <div className={`flex justify-center mt-8 mb-0`}>
          <a
            onClick={() => {
              navigate("#")
            }}
            className="bg-yellow-300"
          >
            ↑ back to top ↑
          </a>
        </div>
      </main>
    </div>
  )
}

export default Layout
