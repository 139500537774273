import * as React from "react"
import Link from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import FrontmatterSection from "./frontmatterSection"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { MarkdownLink } from "./mdx"

const AnimatedLink = ({ children, to }) => (
  <AniLink paintDrip hex="EDF1F5" direction="up" duration={0.8} to={to}>
    {children}
  </AniLink>
)

const ProjectBlock = ({
  slug,
  frontmatter,
  isProjectDetailPage = false,
  i,
}) => {
  const { featured, overrideLink } = frontmatter

  // if the project has a override external link
  // use markdown link (external) instead
  const ProjectLink = overrideLink ? MarkdownLink : AnimatedLink

  const [showDetail, setShowDetail] = React.useState(isProjectDetailPage)

  // console.log("Is ProjectDetailPage: ", isProjectDetailPage)

  return (
    <div
      key={`project-${i}`}
      id={i}
      className={`flex flex-col-reverse md:grid md:grid-cols-12 gap-0 md:gap-8 pb-6 md:pt-6
      border-dashed border-t-2 md:border-t-0 md:border-b-2 last:border-0 border-zinc-300 ${
        isProjectDetailPage && "first:border-0"
      }`}
    >
      <div className="col-span-4 pt-4 md:pt-0">
        <h2 className="pb-2 font-bold text-lg md:text-base ">
          <ProjectLink
            to={isProjectDetailPage ? "#main-content" : "/" + slug}
            href={overrideLink}
          >
            <span
              className={`transition-all underline hover:bg-blue-700 hover:text-white 
              `}
            >
              {frontmatter.title}
            </span>
          </ProjectLink>

          {featured && (
            <span
              className="group transition-all mx-2 px-1 font-light rounded-lg bg-yellow-400 
              hover:bg-blue-700"
            >
              <span className="inline-block animate-pulse transition-all group-hover:scale-[2] group-hover:animate-spin cursor-none">
                👀
              </span>
              <span className="hidden group-hover:inline-block text-white italic text-sm mx-2">
                featured project
              </span>
            </span>
          )}
        </h2>
        <FrontmatterSection
          isProjectDetailPage={isProjectDetailPage}
          data={frontmatter.description}
        ></FrontmatterSection>

        {/* show more detail button in mobile */}
        <div className="flex justify-end">
          <a
            className={`cursor-pointer text-sm leading-tight
      ${showDetail ? "hidden" : "block"} md:hidden`}
            onClick={() => {
              setShowDetail(!showDetail)
            }}
          >
            {"↓more↓"}
          </a>
        </div>

        {/* Detail*/}
        <div
          className={`transition-all overflow-hidden text-sm leading-tight ${
            showDetail ? "h-auto" : "h-0"
          } md:h-auto`}
        >
          {/* Tags */}
          <FrontmatterSection
            isProjectDetailPage={isProjectDetailPage}
            title="Tags"
            data={frontmatter.tags}
          ></FrontmatterSection>

          <FrontmatterSection
            isProjectDetailPage={isProjectDetailPage}
            title="Skills"
            data={frontmatter.skills}
          ></FrontmatterSection>

          {/* Role */}
          <FrontmatterSection
            isProjectDetailPage={isProjectDetailPage}
            title="Role"
            data={frontmatter.role}
          ></FrontmatterSection>

          {/* Collaborators */}
          <FrontmatterSection
            isProjectDetailPage={isProjectDetailPage}
            title="Collaborators"
            data={frontmatter.collaborators}
          ></FrontmatterSection>

          {/* Contributors */}
          <FrontmatterSection
            isProjectDetailPage={isProjectDetailPage}
            title="Contributors"
            data={frontmatter.contributors}
          ></FrontmatterSection>

          {/* Notes */}
          <FrontmatterSection
            isProjectDetailPage={isProjectDetailPage}
            title="Notes"
            data={frontmatter.notes}
          ></FrontmatterSection>

          <section className={isProjectDetailPage && "text-right md:text-left"}>
            {frontmatter.video && (
              <span className="mr-2">
                {"→"}{" "}
                <MarkdownLink href={frontmatter.video}>Watch</MarkdownLink>
              </span>
            )}
            {frontmatter.github && (
              <span className="mr-2">
                {"→"}{" "}
                <MarkdownLink href={frontmatter.github}>Github</MarkdownLink>
              </span>
            )}
            {frontmatter.external && (
              <span className="mr-2">
                {"→"}{" "}
                <MarkdownLink href={frontmatter.external}>Access</MarkdownLink>
              </span>
            )}

            {frontmatter.publication && (
              <span className="mr-2">
                {"→"}{" "}
                <MarkdownLink href={frontmatter.publication}>
                  Publication
                </MarkdownLink>
              </span>
            )}
            {frontmatter.social && (
              <span className="mr-2">
                {"→"}{" "}
                <MarkdownLink href={frontmatter.social}>Social</MarkdownLink>
              </span>
            )}
          </section>
        </div>

        <div className={isProjectDetailPage && "hidden"}>
          {/* show less button in mobile */}
          <div className="flex justify-end">
            <a
              className={`cursor-pointer text-sm leading-tight
      ${showDetail ? "block" : "hidden"} md:hidden`}
              onClick={() => {
                setShowDetail(!showDetail)
              }}
            >
              {"↑less↑"}
            </a>
          </div>

          {/* show project link in mobile */}
          <div className="flex justify-end md:justify-start mt-2 text-sm leading-tight  md:hidden ">
            <span>
              {"→"}{" "}
              <ProjectLink to={"/" + slug} href={overrideLink}>
                Project Details
              </ProjectLink>
            </span>
          </div>
        </div>
      </div>

      <div className="col-span-8 pt-4 md:pt-0">
        <div className="flex justify-between pb-2">
          <h2>{frontmatter.displayed_time}</h2>
          <h2 className=" relative text-black font-light italic">
            {/* <span className="absolute right-0 -z-10 bg-zinc-500 text-black pl-2 pr-1 blur-sm rounded-sm">
              {frontmatter.category}
            </span> */}
            <span className="low-highlight backdrop-blur-sm">
              {frontmatter.displayedCategory}
            </span>
          </h2>
        </div>

        <ProjectLink
          to={isProjectDetailPage ? "#main-content" : "/" + slug}
          href={overrideLink}
        >
          <GatsbyImage
            className="transition-all duration-500 border-dashed border-2 border-transparent  
            hover:shadow-xl hover:border-zinc-600 md:hover:border-black hover:bg-zinc-300
             max-h-[400px] lg:max-h-[480px] 
            "
            alt={`Cover image for ${frontmatter.title}`}
            image={getImage(frontmatter.embeddedImages[0])}
          />
        </ProjectLink>
      </div>
    </div>
  )
}
export default ProjectBlock
