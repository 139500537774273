import React, { useState, useEffect } from "react"
import isMobileDevice from "utils/isMobileDevice"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import slugify from "utils/slugify"

const H1 = ({ children }) => {
  return (
    <h1
      className="font-black text-2xl mt-6 pt-6 mb-6 
            underlinee decoration-blue-700decoration-2 underline-offset-4
      "
      id={slugify(children)}
    >
      <span className="border-b-2 border-black hover:border-b-2 hover:border-yellow-400 transition-all">
        {children}
      </span>
      {/* <div className="relative w-36 hover:w-24 border-t-2 border-black hover:border-yellow-400 transition-all"></div> */}
    </h1>
  )
}
const H2 = ({ children }) => (
  <h2
    className="text-xl font-bold mt-6 mb-4 leading-tight"
    id={slugify(children)}
  >
    {/* <div className="relative w-16 hover:w-24 border-t-2 hover:border-blue-700 border-yellow-400 transition-all"></div> */}
    <span className="border-b-2 hover:border-b-2 hover:border-blue-700 border-yellow-400 transition-all">
      {children}
    </span>
    {/* <div className="relative w-16 hover:w-24 border-t-2 hover:border-blue-700 border-yellow-400 transition-all"></div> */}
  </h2>
)
const H3 = ({ children }) => (
  <h3
    className="text-lg font-semibold mt-4 mb-2"
    id={slugify(children)}
    style={{ fontSize: "1.18rem", lineHeight: "1.75rem" }}
  >
    <span className="border-b-2 border-blue-400 hover:border-yellow-400 transition-all">
      {children}
    </span>
    {/* <div className="relative w-12 hover:w-24 border-t-2 border-blue-700 hover:border-yellow-400 transition-all"></div> */}
  </h3>
)
const H4 = ({ children }) => (
  <h4 className="text-lg font-normal mb-3">{children}</h4>
)
const H5 = ({ children }) => <h5 className="text-xl pt-2">{children}</h5>

const H6 = ({ children }) => (
  <h6 className="text-base mb-0">
    <span
      className="
  text-transparent bg-clip-text bg-gradient-to-bl from-pink-700 to-yellow-400
    "
    >
      {children}
    </span>
  </h6>
)

const BLOCKQUOTE = ({ children }) => (
  <blockquote className="ml-4 pl-6 border-l-4 font-semibold border-zinc-600 pt-2 mb-4">
    {children}
  </blockquote>
)

const P = ({ children, width = "6" }) => (
  <p className={` pb-4 font-normal font-body text-lg max-w-4xl`}>{children}</p>
)

// _XXXX_ (italic) means low-highlight effect
const EM = ({ children }) => <span className="low-highlight">{children}</span>

// **XXXX** (bold) means low-highlight effect + bold
const STRONG = ({ children }) => (
  <span className="font-semibold">{children}</span>
)

const UL = ({ children }) => (
  <ul className="pl-6 font-body list-disc">{children}</ul>
)

const IMG = ({ children, src, alt }) => {
  const [zoomMargin, setZoomMargin] = useState(100)

  useEffect(() => {
    if (isMobileDevice()) setZoomMargin(0)
  })

  return (
    <section className="pt-3 pb-6 px-0 md:px-8 m-auto">
      <Zoom
        zoomMargin={zoomMargin}
        overlayBgColorEnd="rgba(100, 100, 100, 0.95)"
      >
        <img
          src={src}
          alt={alt}
          className="transition-all duration-700 shadow-md hover:shadow-lg"
        ></img>
      </Zoom>
    </section>
  )
}

const HR = () => <div className="py-2"></div>

export { H1, H2, H3, H4, H5, H6, P, STRONG, EM, BLOCKQUOTE, IMG, UL, HR }
