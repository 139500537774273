import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import Link from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Helmet from "react-helmet"
import { motion, useAnimation } from "framer-motion"
import { StaticQuery, graphql } from "gatsby"

import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { MarkdownLink } from "./mdx"

import { detectMobileAndTablet, isSSR } from "../utils/"
import NavLinks from "../../content/navLinks"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import TableOfContent from "components/mdx/tableOfContent"

const AnimatedLink = ({ children, to, className }) => (
  <AniLink
    paintDrip
    hex="EDF1F5"
    direction="up"
    duration={0.8}
    to={to}
    className={className}
  >
    {children}
  </AniLink>
)

const Header = ({
  location,
  category,
  pageType,
  tableOfContent,
  headings,
  setShowMore,
}) => {
  const H1 = ({ children }) => <h1 className="pb-4">{children}</h1>
  const P = ({ children }) => (
    <p className="pb-4 font-normal text-base md:text-sm lg:text-base lg:leading-5">
      {children}
    </p>
  )
  const [more, setMore] = useState(true)

  // format category other than 'design' or 'art' into "other"
  if (category && !["art", "design"].includes(category)) category = "other"

  // console.log("cat", category)
  // console.log("headerlocation", location)

  return (
    <StaticQuery
      query={graphql`
        {
          about: allMdx(filter: { fileAbsolutePath: { regex: "/shortBio/" } }) {
            edges {
              node {
                body
                frontmatter {
                  title
                  embeddedImages {
                    childImageSharp {
                      gatsbyImageData(width: 800)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const aboutMDX = data.about.edges[0].node

        return (
          <header className="flex justify-between h-full flex-col">
            <div
              className={`transition-all
            ${
              pageType === "project-detail"
                ? "opacity-100 md:opacity-30 hover:opacity-100"
                : ""
            }`}
            >
              <MDXProvider
                components={{
                  h1: H1,
                  p: P,
                  a: MarkdownLink,
                }}
              >
                {/* title */}
                <div className="flex justify-between pb-2">
                  <h1>
                    <AnimatedLink
                      to="/#"
                      className="font-bold text-lg md:text-base md:leading-4"
                    >
                      {aboutMDX.frontmatter.title}
                    </AnimatedLink>
                  </h1>

                  <div className="hidden md:block">
                    {/* if path not root, show back button */}
                    {/* otherwise show "feeling lucky" */}
                    {location.pathname !== "/" ? (
                      <h1 className="transition-all hover:-translate-x-1">
                        <a
                          className="cursor-pointer bg-yellow-300 text-black"
                          onClick={() => navigate(-1)}
                        >
                          {"←back"}
                        </a>
                      </h1>
                    ) : (
                      // <h1 className="transition-all hover:translate-x-10 md:animate-rotate-lucky">
                      //   <a
                      //     className="cursor-pointer bg-yellow-300 text-black"
                      //     onClick={() =>
                      //       navigate("#" + Math.floor(Math.random() * 17.99))
                      //     }
                      //   >
                      //     {"feeling lucky→"}
                      //   </a>
                      // </h1>
                      <h1 className="transition-all  hover:translate-x-1 hover:animate-spin">
                        <a
                          className="cursor-pointer bg-yellow-300 hover:bg-transparent italic text-black"
                          onClick={() => {
                            setShowMore(!more)
                            setMore(!more)
                          }}
                        >
                          {more ? "grid→" : "list→"}
                        </a>
                      </h1>
                    )}
                  </div>
                </div>

                {/* don't display bio if in project detail */}
                <div
                  className={`${
                    pageType === "project-detail" && "hidden"
                  } border-0 md:border-b-2 border-dashed border-zinc-300`}
                >
                  <div className="absolute left-0 md:relative w-full -z-10 md:z-0">
                    <GatsbyImage
                      className="transition-all duration-700 rounded-full mb-2 w-full md:w-32 opacity-20 saturate-0
                      md:opacity-100 md:hover:-rotate-[360deg]
                       md:hover:shadow-2xl
                      md:saturate-0 hover:md:saturate-100 md:hover:w-48 lg:hover:w-64
                     cursor-zoom-in
                     md:animate-rotate-360-48 lg:animate-rotate-360-48
                     
                     "
                      alt={`Photo of ${aboutMDX.frontmatter.title}`}
                      image={getImage(aboutMDX.frontmatter.embeddedImages[0])}
                    />
                  </div>
                  <MDXRenderer>{aboutMDX.body}</MDXRenderer>
                </div>

                {/* navigation links */}
                <div className="grid grid-cols-3 md:grid-cols-1 mt-4 ">
                  {NavLinks.map((link, i) => (
                    <section key={`nav-link-${i}`} className="pb-4">
                      <h2
                      // check whether is current path
                      >
                        {link.url ? (
                          <AnimatedLink
                            to={link.url}
                            className={
                              (location.pathname + location.search).trim() ===
                                link.url.trim() && "text-blue-700"
                            }
                          >
                            {link.name}
                          </AnimatedLink>
                        ) : (
                          link.name
                        )}
                      </h2>
                      {link.subLinks &&
                        link.subLinks.map((sublink, j) => (
                          <h2
                            key={`nav-link-${i},${j}`}
                            className={
                              // check whether is current path
                              ((location.pathname + location.search).trim() ===
                                sublink.url.trim() && "text-blue-700") +
                              " " +
                              // check whether is current project category
                              (category === sublink.name.toLowerCase() &&
                                "text-blue-700") +
                              " my-1"
                            }
                          >
                            {"→ "}
                            <MarkdownLink href={sublink.url}>
                              {sublink.name}
                            </MarkdownLink>
                          </h2>
                        ))}
                    </section>
                  ))}
                </div>
                <span className="text-yellow-500"></span>
              </MDXProvider>
            </div>
            {tableOfContent && (
              <div
                className="sticky top-0 
          transition-all hidden lg:block mb-36"
              >
                <TableOfContent headings={headings}></TableOfContent>
              </div>
            )}

            {!tableOfContent && (
              <p className="hidden md:block italic font-normal text-xs text-zinc-400">
                Made with{" "}
                <MarkdownLink href="https://www.gatsbyjs.com/">
                  gatsby.js
                </MarkdownLink>
                , rendered from{" "}
                <MarkdownLink href="https://mdxjs.com/">mdx</MarkdownLink>.
              </p>
            )}
          </header>
        )
      }}
    />
  )
}

export default Header
