import MarkdownLink from "./markdownLink"
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  STRONG,
  EM,
  BLOCKQUOTE,
  UL,
  IMG,
  HR,
} from "./headings"
import * as LayoutElements from "./layout"

export { MarkdownLink, H1, H2, H3, H4, H5, H6, P, STRONG, EM, BLOCKQUOTE }

const shortcode = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  blockquote: BLOCKQUOTE,
  p: P,
  a: MarkdownLink,
  em: EM,
  ul: UL,
  strong: STRONG,
  img: IMG,
  hr: HR,
  ...LayoutElements,
}

export default shortcode
