import * as React from "react"
import CollaboratorWebsites from "../../content/collaboratorWebsites"
import Link from "gatsby-plugin-transition-link"
import { MarkdownLink } from "./mdx"

const FrontmatterSection = ({ title, data, isProjectDetailPage, isGrid }) => {
  let section = null
  if (!data) return section
  // if data is an array
  if (Array.isArray(data)) {
    // and elements exist
    if (data[0] && data.length > 0) {
      // and if title is "Collaborators", return Links as well
      if (title === "Collaborators" || title === "Contributors") {
        section = (
          <section className="pb-4">
            <h3>{title}:</h3>
            <p className="font-normal">
              {data.map((e, i) => {
                const l = data.length - 1
                const website = CollaboratorWebsites[e]

                if (website)
                  return (
                    <span key={`${title}-${i}`}>
                      {/* only insert link in project detail page */}
                      {isProjectDetailPage ? (
                        <MarkdownLink href={website}>{e}</MarkdownLink>
                      ) : (
                        // e
                        <MarkdownLink href={website}>{e}</MarkdownLink>
                      )}

                      {i === l ? "." : ", "}
                    </span>
                  )
                // insert commas and period
                return (
                  <span key={`${title}-${i}`}>
                    {e + (i === l ? "." : ", ")}
                  </span>
                )
              })}
            </p>
          </section>
        )

        // and if title is not "Collaborators"
      } else {
        section = (
          <section className="pb-4">
            <h3>{title}:</h3>
            <p
              className={`font-normal ${
                title === "Tags" ? "lowercase italic " : ""
              }`}
            >
              {data.map((e, i) => {
                const l = data.length - 1
                // insert commas and period
                return (
                  <span key={`${title}-${i}`}>
                    {e + (i === l ? "." : ", ")}
                  </span>
                )
              })}
            </p>
          </section>
        )
      }
    }
  }
  // if data is not an array and title exist
  else if (title) {
    section = (
      <section className="pb-4">
        <h3>{title}:</h3>
        <p className="font-normal text">{data}</p>
      </section>
    )

    if (title === "Notes") {
      section = (
        <section className="pb-4">
          <h3>{title}:</h3>
          <span className="font-normal italic low-highlight">{data}</span>
        </section>
      )
    }
  } else {
    // if title does not exist
    return (
      <section className="pb-4">
        <p className="font-body md:font-sans font-normal leading-tight text-lg md:text-sm xl:text-base xl:leading-tight ">
          {data}
        </p>
      </section>
    )
  }

  if (!isProjectDetailPage)
    return (
      <div className="transition-all opacity-100 md:opacity-50 hover:opacity-100">
        {section}
      </div>
    )
  else {
    return section
  }
}

export default FrontmatterSection
