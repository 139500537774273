import React, { useEffect, useState } from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import isMobileDevice from "utils/isMobileDevice"

import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

const AutoImage = ({
  image,
  alt,
  noShadow = false,
  background,
  noZoom = false,
}) => {
  const shadowClassName =
    "transition-all duration-700 shadow-md hover:shadow-lg"

  const [zoomMargin, setZoomMargin] = useState(100)

  useEffect(() => {
    if (isMobileDevice()) setZoomMargin(0)
  })

  let imageEl

  if (typeof image === "string" || image instanceof String) {
    if (image.endsWith(".mp4")) {
      imageEl = (
        <video
          autoPlay
          muted
          loop
          webkit-playsInline
          playsInline
          poster={image.slice(0, image.length - 4) + ".jpg"}
        >
          <source src={image} type="video/mp4" />
        </video>
      )
    } else
      imageEl = (
        <img
          src={image}
          alt={alt}
          className={!noShadow && shadowClassName + " w-screen"}
        ></img>
      )
  } else
    imageEl = (
      <GatsbyImage
        alt={alt}
        image={getImage(image)}
        className={!noShadow && shadowClassName}
      />
    )

  if (noZoom) return imageEl

  return (
    <Zoom zoomMargin={zoomMargin} overlayBgColorEnd="rgba(0, 0, 0, 0.8)">
      {imageEl}
    </Zoom>
  )
}

const EmbededVideo = ({ children, videoSrcURL, videoTitle }) => (
  <section className={`relative overflow-hidden -mx-4 my-6 md:mx-6 mb-8`}>
    <div className="w-full aspect-video">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
    </div>
  </section>
)

const BlockQuote = ({ children }) => (
  <blockquote
    className="ml-2 pl-6 py-2 mb-2
  font-normal text-2xl 
  border-l-4 border-yellow-400 hover:border-blue-700 transition-all
  max-w-4xl
  "
  >
    <p className="font-normal font-body text-zinc-600 hover:text-black leading-normal">
      {children}
    </p>
  </blockquote>
)

const SubHeading = ({ children, h = 1 }) => (
  <p
    className={`text-lg font-semibold ${h === 1 && "-mt-4 mb-6"} ${
      h === 2 && "-mt-2 mb-4"
    } ${h === 3 && "mb-2"}text-zinc-700`}
    style={{ fontSize: "1.18rem", lineHeight: "1.75rem" }}
  >
    → {children}
  </p>
)

const EmStrong = ({ children }) => (
  <span className="low-highlight font-bold">{children}</span>
)

const Image = ({
  image,
  alt,
  caption,
  halfWidth = false,
  noShadow = false,
}) => (
  <section
    className={`pt-3 pb-6 px-0 md:px-8 m-auto
    transition-all duration-700 
    !hover:scale-[102%] !hover:-rotate-[0.5deg]
    w-full
`}
  >
    <div className={`${halfWidth && "w-1/2 m-auto"}`}>
      <AutoImage alt={alt ? alt : caption} image={image} noShadow={noShadow} />

      <figcaption className="mt-2 italic text-sm font-normal text-zinc-600">
        {caption}
      </figcaption>
    </div>
  </section>
)

const ImageTextSection = ({
  children,
  imgPos = "left",
  textPos = "top",
  image,
  alt,
  caption,
  noShadow = false,
  widerImage = false,
}) => {
  const widerColSpan = "span 9 / span 9"
  const shorterColSpan = "span 3 / span 3"

  const imageDescription = (
    <div
      className={`col-span-5 pt-4 lg:pt-0 ${
        textPos !== "top" ? "mt-auto" : ""
      }`}
      style={{ gridColumn: widerImage && shorterColSpan }}
    >
      <figcaption className="text-base font-medium">{caption}</figcaption>
      <p className="pt-2 italic font-normal font-body text-sm text-zinc-600">
        {children}
      </p>
    </div>
  )

  return (
    <section
      className={`flex ${
        imgPos === "right" ? "flex-col-reverse" : "flex-col"
      } lg:grid lg:grid-cols-12 gap-0 lg:gap-8 mt-6 mb-8 px-0`}
    >
      {/* if position is NOT "right" */}
      {imgPos === "right" && imageDescription}

      {/* image */}
      <div
        className={`col-span-7 pt-4 md:pt-0`}
        style={{ gridColumn: widerImage && widerColSpan }}
      >
        <AutoImage
          alt={alt ? alt : caption}
          image={image}
          noShadow={noShadow}
        />
      </div>

      {/* if position is "right" */}
      {imgPos !== "right" && imageDescription}
    </section>
  )
}

const ImageList = ({
  images,
  alt,
  captions,
  width = 100,
  noShadow = false,
  colNum,
  mobileCompact = false, // decide if di
}) => {
  const count = colNum ? colNum : images.length

  const template = count ? "1fr ".repeat(count) : null

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isMobileDevice())
    const handler = (e) => setIsMobile(!e.matches)
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler)
  })

  return (
    <section
      className={`pt-3 pb-6 px-2 m-auto w-full
    ${mobileCompact ? "grid-flow-col" : "grid-flow-row"}
    grid md:grid-flow-col gap-4
`}
      style={{ gridTemplateColumns: !isMobile && template }}
    >
      {images.map((image, i) => (
        <div
          className=" transition-all duration-700 
    !hover:scale-[102%] !hover:-rotate-[0.5deg] col-span-1"
        >
          <AutoImage
            aalt={alt ? alt : captions[i]}
            image={image}
            noShadow={noShadow}
          />

          <figcaption className="mt-2 italic text-sm font-normal text-zinc-600">
            {captions[i]}
          </figcaption>
        </div>
      ))}
    </section>
  )
}

const ImageSlider = ({
  children,
  images,
  alts,
  captions,
  noShadow = false,
  autoPlay = false,
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isMobileDevice())

    const handler = (e) => setIsMobile(!e.matches)
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler)
  })

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={50}
      navigation={!isMobile}
      pagination={isMobile}
      className="md:m-6"
      autoplay={
        autoPlay && {
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }
      }
    >
      {images.map((image, i) => (
        <SwiperSlide className="my-auto px-0 md:px-12">
          <AutoImage
            alt={alts ? alts[i] : captions[i]}
            image={image}
            noShadow={noShadow}
            noZoom={isMobile}
          />

          <figcaption className="mt-2 italic text-sm font-normal text-zinc-600 mb-10 md:mb-0">
            {captions[i]}
          </figcaption>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const Grid = ({ children, length }) => {
  const count = length || React.Children.toArray(children).length
  const template = count ? "1fr ".repeat(count) : null

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isMobileDevice())
    const handler = (e) => setIsMobile(!e.matches)
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler)
  })

  return (
    <div
      className={`grid grid-flow-row md:grid-flow-row gap-4 text-lg mt-2 mb-8`}
      style={{ gridTemplateColumns: !isMobile && template }}
    >
      <span className="hidden grid-cols-6"></span>
      {children}
    </div>
  )
}

const Cell = ({ children, color, className }) => {
  return (
    <div
      className={
        `bg-yellow-200 p-4 transition-all duration-700 shadow-md hover:shadow-lg row-span-1 col-span-1 leading-snug ` +
        className
      }
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  )
}

const BulletCell = ({ children, color, className, emoji, title }) => {
  return (
    <div
      className={
        `bg-yellow-200 p-4 transition-all duration-700 shadow-md hover:shadow-lg row-span-1 col-span-1 leading-snug flex flex-row ` +
        className
      }
      style={{ backgroundColor: color }}
    >
      {emoji && <div className="mr-2">{emoji}</div>}
      <div>
        {title && (
          <b>
            {title} <br />
          </b>
        )}
        <div className="font-body">{children}</div>
      </div>
    </div>
  )
}

const ImageCell = ({ children, color, className, image, title, alt }) => {
  return (
    <div
      className={
        `bg-yellow-200 p-4 transition-all duration-700 shadow-md hover:shadow-lg row-span-1 col-span-1 leading-snug flex flex-col ` +
        className
      }
      style={{ backgroundColor: color }}
    >
      <div className="-mt-4 -mx-4">
        <AutoImage alt={alt ? alt : children} image={image} noShadow />
      </div>

      <div>
        {title && <div className="font-bold my-2">{title}</div>}
        <div className="font-body">{children}</div>
      </div>
    </div>
  )
}

const SectionDivider = () => <div className="py-16"></div>

export {
  EmbededVideo,
  ImageTextSection,
  Image,
  Grid,
  Cell,
  BulletCell,
  ImageCell,
  ImageList,
  ImageSlider,
  BlockQuote,
  EmStrong,
  SubHeading,
  SectionDivider,
}
